import { Loading, Translator } from '@components';
import { useDeposit, useProfile, usePurchase, useToken, useWithdraw } from '@contexts-investor';
import { useEffect, useState } from 'react';
import { OverlayTrigger, Tooltip, Col, Row } from 'react-bootstrap';

import StepsCard from './StepsCard';
import { logoCriptech } from '@assets';

const Dashboard = () => {
  const [showBalance, setShowBalance] = useState(false);

  const { loading, getDeposits, myDeposits } = useDeposit();
  const { loadingPurchase, listPurchases, myPurchases } = usePurchase();
  const { loading: loadingWithdraw, listWithdraw, myWithdraw } = useWithdraw();
  const { loadProfile, profile, loadingProfile, getTotalAssets, totalAssets } = useProfile();
  const { myTokens, loadingMyTokens, loadMyTokens } = useToken();

  useEffect(() => {
    const userId = localStorage.getItem('jwt-userId');
    getDeposits(0, undefined, userId);
    listPurchases(0, undefined, userId);
    listWithdraw(0, undefined, userId);
    loadMyTokens(userId);
    loadProfile(userId);
  }, []);

  useEffect(() => {
    if (profile && myTokens) {
      getTotalAssets(profile, myTokens);
    }
  }, [profile, myTokens]);

  const renderTooltip = (props = { ...props }) => (
    <Tooltip {...props}>
      <Translator path={'dashboard.totalAssetsInfo'} />
    </Tooltip>
  );

  if (loading || loadingPurchase || loadingWithdraw || loadingProfile || loadingMyTokens) {
    return <Loading />;
  }

  return (
    <div className="row home-wrapper">
      <div className='col-12 balance-div'>
        <div className='col-xxl-4 col-xl-5 col-lg-12 col-md-12 col-sm-12 balance balance-funds'>
          <label>
            <Translator path={'dashboard.currentBalance'} />:
          </label>
          <input
            className='col-5'
            readOnly={true}
            type={showBalance ? 'text' : 'password'}
            value={
              profile?.wallet
                ? profile?.wallet?.balance.toLocaleString('pt-BT', { style: 'currency', currency: 'BRL' })
                : 'R$ 0,00'
            }
          />
          {showBalance ? (
            <i
              className="material-icons cursor-pointer"
              style={{ width: 'fit-content' }}
              onClick={() => setShowBalance(!showBalance)}
            >
              visibility_off
            </i>
          ) : (
            <i
              className="material-icons cursor-pointer"
              style={{ width: 'fit-content' }}
              onClick={() => setShowBalance(!showBalance)}
            >
              visibility
            </i>
          )}
        </div>
        <div className='col-xxl-4 col-xl-5 col-lg-12 col-md-12 col-sm-12 balance balance-patrimony'>
          <label>
            <Translator path={'dashboard.totalAssets'} />:
          </label>
          <input
            className='col-5'
            readOnly={true}
            value={
              totalAssets ? totalAssets.toLocaleString('pt-BT', { style: 'currency', currency: 'BRL' }) : 'R$ 00,00'
            }
          />
          <i className="material-icons cursor-pointer align-icon" style={{ width: 'fit-content' }}>
            info
          </i>
        </div>
      </div>

      <div className="container first-steps gx-0 gy-0">
        <div className="col-12">
          <div className="step-title">
            <h4><Translator path={'dashboard.firstSteps'} /></h4>
          </div>
        </div>
        <div className="row steps-div" style={{ marginTop: '1rem', padding: '1rem' }}>
          <div className="col-lg-4 col-md-12 col-sm-6">
            <div className="investor-step">
              <StepsCard step={1} deposits={myDeposits} purchases={myPurchases} withdrawals={myWithdraw} />
            </div>
          </div>
          <div className="col-lg-4 col-md-12 col-sm-6">
            <div className="investor-step">
              <StepsCard step={2} deposits={myDeposits} purchases={myPurchases} withdrawals={myWithdraw} />
            </div>
          </div>
          <div className="col-lg-4 col-md-12 col-sm-6">
            <div className="investor-step">
              <StepsCard step={3} deposits={myDeposits} purchases={myPurchases} withdrawals={myWithdraw} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
