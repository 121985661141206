import React, { useState } from 'react';
import { useEffect } from 'react';
import { useProfile } from '@contexts-investor';
import { specialToCode } from '@utils';
import { Loading } from '@components';
import FinalStep from './FinalStep';
import FirstStep from './FirstStep';

const SecurityEdit = () => {
  const [code, setCode] = useState();
  const [currentStep, setCurrentStep] = useState(1);
  const { profile, sendResetToken, loadingProfile, loadingButton, validateToken } = useProfile();

  const handleStep = async () => {
    if (code) {
      const userId = localStorage.getItem('jwt-userId');
      await validateToken(code, setCurrentStep, userId);
    }
  };

  useEffect(() => {
    if (profile?.physicalUser) {
      sendResetToken(specialToCode(profile.physicalUser.email));
    }
    if (profile?.legalUser) {
      sendResetToken(specialToCode(profile.legalUser.loginLegalUser.email));
    }
  }, [profile]);

  if (loadingProfile || loadingButton) return <Loading />;

  if (profile)
    return (
      <div className="page-wrapper">
        <div className="row justify-content-center align-items-center modifier-withdraw">
          <div className="row col-xl-7 col-lg-7 col-md-7 col-sm-10 col-sx-11 d-flex flex-column align-items-center content-formater-withdraw">
            {currentStep === 1 ? (
              <FirstStep props={{ email: profile.physicalUser ? profile.physicalUser.email : profile.legalUser.loginLegalUser.email, setCode: setCode, handleStep: handleStep }} />
            ) : (
              <FinalStep props={{ code: code }} />
            )}
          </div>
        </div>
      </div>
    );
};

export default SecurityEdit;
