import { useState, useEffect } from 'react';

import { Translator, Loading, Table } from '@components';
import { emptyDeposits, noData } from '@assets';
import { useWithdraw } from '@contexts-investor';

import { COLUMNS } from './TableColumns';
import FilterModal from './FilterModal';
import Pagination from '@mui/material/Pagination';

const WithdrawList = () => {
  const { loading, listWithdraw, myWithdraw, totalWithdraw } = useWithdraw();
  const [filters, setFilters] = useState();
  const [withdraws, setWithdraws] = useState();
  const [showModal, setShowModal] = useState(false);
  const [pages, setPages] = useState(0);
  const itemsPerPage = 12;
  const totalPages = Math.ceil(totalWithdraw / itemsPerPage);

  const handleChange = (event, value) => {
    setPages(value)
  };

  useEffect(() => {
    const userId = localStorage.getItem('jwt-userId');
    listWithdraw(pages, filters, userId);
  }, [filters, setFilters, pages, setPages]);

  useEffect(() => {
    if (myWithdraw) {
      const withdraws = myWithdraw.map((item) => {
        return {
          ...item,
          day: new Date(item.createdAt).toLocaleDateString('pt-BR'),
          form: 'Pix',
          formatedValue:
            'R$ ' + new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(parseFloat(item.value)),
        };
      });
      setWithdraws(withdraws);
    }
  }, [myWithdraw]);

  if (loading) {
    return <Loading />;
  }

  if (withdraws)
    return (
      <div className="page-wrapper">
        <div className="row statement-row-modifier justify-content-between">
          <h3 className="col-6 statement-header" style={{ whiteSpace: 'nowrap', marginBottom: '1.25rem' }}>
            <Translator path="titles.walletItems.withdraw" />
          </h3>
          <button
            className={`btn btn-orange col-2 ${myWithdraw.length === 0 && !filters ? 'disabled' : ''}`}
            style={{ marginBottom: '1.25rem' }}
            onClick={() => setShowModal(true)}
          >
            <Translator path="depositPage.filter" />
          </button>
          <Table
            props={{
              tableData: withdraws,
              tableColumns: COLUMNS,
              emptyImage: filters ? noData : emptyDeposits,
            }}
          />
          {totalPages > 1 ? (
            <div className='pagination-div'>
              <Pagination
                count={totalPages}
                page={pages}
                variant="outlined"
                shape="rounded"
                onChange={handleChange}
              />
            </div>
          ) : (
            ''
          )}
          <FilterModal props={{ show: showModal, setShow: setShowModal, setFilters: setFilters, filters }} />
        </div>
      </div>
    );
};

export default WithdrawList;
