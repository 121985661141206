import { accountBalanceWallet, emptyStatement, moneyIncomingPig, museumIcon, pigbank } from '@assets';
import { Loading, PurchaseList, TokenCard, Translator } from '@components';
import { useProfile, usePurchase, useToken } from '@contexts-investor';
import { useEffect, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import { useNavigate } from 'react-router-dom';
import DepositModal from './DepositModal';

const Wallet = () => {
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const { profile, loadingProfile, getTotalAssets, totalAssets } = useProfile();
  const { myTokens, highlightToken, loadingMyTokens, getLatestHighlightToken } = useToken();
  const { myPurchases, listUserPurchases } = usePurchase();

  const [showBalance, setShowBalance] = useState(false);

  const renderTooltip = (props = { ...props }) => (
    <Tooltip {...props}>
      <Translator path={'dashboard.totalAssetsInfo'} />
    </Tooltip>
  );

  useEffect(() => {
    const userId = localStorage.getItem('jwt-userId');
    listUserPurchases(userId);
    getLatestHighlightToken();
  }, []);

  useEffect(() => {
    console.log('highlightToken: ', highlightToken);
    getTokensQuantity(myTokens);
    getTotalAssets(profile, myTokens);
  }, [profile, myTokens]);

  const getTokensQuantity = (tokens) => {
    let quantity = 0;
    tokens?.forEach((token) => {
      let quantityToken = 0;

      token.purchases?.forEach((purchase) => (quantityToken += purchase.boughtQuantity));

      quantity += quantityToken;
    });
  };

  if (loadingProfile || loadingMyTokens) return <Loading />;

  return (
    <>
      <div className={`page-wrapper`}>
        <div className="browse-job d-flex flex-column gap-5">
          <section className="patrimony-container d-flex justify-content-start align-items-center">
            <div className="money-and-actions d-flex flex-column justify-content-start align-items-center">
              <div className="wallet-infos d-flex flex-row justify-content-right flex-md-nowrap">
                <div className="funds-wallet flex-md-wrap">
                  <div
                    className="d-flex align-items-center justify-content-sm-around"
                  >
                    <p className="col-5">
                      <Translator path={'dashboard.currentBalance'} />:
                    </p>
                    <input
                      className="col-5"
                      readOnly={true}
                      type={showBalance ? 'text' : 'password'}
                      value={
                        profile?.wallet
                          ? profile?.wallet?.balance.toLocaleString('pt-BT', { style: 'currency', currency: 'BRL' })
                          : 'R$ 0,00'
                      }
                    />
                    {showBalance ? (
                      <i
                        className="material-icons cursor-pointer"
                        style={{ width: 'fit-content' }}
                        onClick={() => setShowBalance(!showBalance)}
                      >
                        visibility_off
                      </i>
                    ) : (
                      <i
                        className="material-icons cursor-pointer"
                        style={{ width: 'fit-content' }}
                        onClick={() => setShowBalance(!showBalance)}
                      >
                        visibility
                      </i>
                    )}
                  </div>
                </div>
                <div className="patrimony-wallet">
                  <div className="d-flex align-items-center justify-content-sm-around">
                    <p className="col-6">
                      <Translator path={'dashboard.totalAssets'} />:
                    </p>

                    <input
                      className="col-5"
                      readOnly={true}
                      value={
                        totalAssets
                          ? totalAssets.toLocaleString('pt-BT', { style: 'currency', currency: 'BRL' })
                          : 'R$ 00,00'
                      }
                    />
                    <OverlayTrigger overlay={renderTooltip} placement="top">
                      <i className="material-icons cursor-pointer" style={{ width: 'fit-content' }}>
                        info
                      </i>
                    </OverlayTrigger>
                  </div>
                </div>
              </div>

              <div className="wallet-actions d-flex flex-row justify-content-center align-items-center flex-md-nowrap">
                <div
                  onClick={() => setShowModal(true)}
                  className="action-box card-hover d-flex align-items-center justify-content-center"
                >
                  <img src={pigbank} alt="pig image" style={{ width: '8rem', height: '8rem', borderRadius: '12px' }} />
                  <p>
                    <Translator path={'walletPage.deposit'} />
                  </p>
                  <img src={accountBalanceWallet} alt="" style={{ width: '20px', height: '20px' }} />
                </div>
                <div
                  onClick={() => navigate('/wallet/withdraw')}
                  className="action-box card-hover d-flex align-items-center justify-content-center"
                >
                  <img
                    src={moneyIncomingPig}
                    alt="pig money"
                    style={{ width: '8rem', height: '8rem', borderRadius: '12px' }}
                  />
                  <p>
                    <Translator path={'walletPage.withdraw'} />
                  </p>
                  <img src={museumIcon} alt="museum icon" style={{ width: '20px', height: '20px' }} />
                </div>
              </div>
            </div>

            <div className="emphasis-token-container d-flex align-items-center justify-content-center">
              {highlightToken?.length > 0 ? (
                <div onClick={() => navigate(`/investment/${highlightToken[0].tokenId}`)} style={{ width: '100%', height: '100%' }}>
                  <TokenCard props={highlightToken[0]} title="walletPage.emphasisToken" showStatus={false} />
                </div>
              ) : (
                <div className="emphasis-token-empty-feedback d-flex align-items-center justify-content-center">
                  <h4 style={{ position: 'absolute', margin: '20px', top: 0, left: 0 }}>
                    <Translator path={'walletPage.emphasisToken'} />
                  </h4>
                  <img src={emptyStatement} alt="empty token" style={{ width: '50%' }} />
                </div>
              )}
            </div>
          </section>

          <section className="my-investments">
            <h2>
              <Translator path={'walletPage.lastInvestments'} />
            </h2>
            <PurchaseList props={myPurchases} />
          </section>
        </div>
      </div>
      <DepositModal props={{ show: showModal, setShow: setShowModal, profile }} />

    </>
  );
};

export default Wallet;
