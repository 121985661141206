import { useState, useEffect } from 'react';
import { useDeposit } from '@contexts-investor';
import { Table, Loading, Translator } from '@components';
import { COLUMNS } from './TableColumn';
import { emptyDeposits } from '@assets';
import FilterModal from './FilterModal';
import Pagination from '@mui/material/Pagination';

const Deposit = () => {
  const { loading, getDeposits, myDeposits, totalDeposits } = useDeposit();
  const [deposits, setDeposits] = useState();
  const [filters, setFilters] = useState();
  const [showModal, setShowModal] = useState(false);
  const [pages, setPages] = useState(0);
  const itemsPerPage = 12;

  const totalPages = Math.ceil(totalDeposits / itemsPerPage);

  const handleChange = (event, value) => {
    setPages(value)
  };

  useEffect(() => {
    const userId = localStorage.getItem('jwt-userId');
    getDeposits(pages, filters, userId);
  }, [filters, setFilters, pages, setPages]);

  useEffect(() => {
    if (myDeposits) {
      const deposits = myDeposits.map((item) => {
        return {
          ...item,
          day: new Date(item.createdAt).toLocaleDateString('pt-BR'),
          time: `${new Date(item.createdAt).getHours()}:${new Date(item.createdAt).getUTCMinutes() < 10
            ? `0${new Date(item.createdAt).getUTCMinutes()}`
            : new Date(item.createdAt).getUTCMinutes()
            }`,
          formatedValue:
            'R$ ' + new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(parseFloat(item.value)),
          type: 'Pix',
        };
      });
      setDeposits(deposits);
    }
  }, [myDeposits, getDeposits]);

  if (loading) {
    return <Loading />;
  }

  if (deposits)
    return (
      <div className="page-wrapper">
        <div className="row statement-row-modifier justify-content-between">
          <h3 className="col-6 statement-header" style={{ whiteSpace: 'nowrap', marginBottom: '1.25rem' }}>
            <Translator path="depositPage.title" />
          </h3>
          <button
            className={`btn btn-orange col-2 ${deposits.length === 0 && !filters ? 'disabled' : ''}`}
            style={{ marginBottom: '1.25rem' }}
            onClick={() => setShowModal(true)}
          >
            <Translator path="depositPage.filter" />
          </button>
          <Table
            props={{
              tableData: deposits,
              tableColumns: COLUMNS,
              emptyImage: emptyDeposits,
            }}
          />
          {totalPages > 1 ? (
            <div className='pagination-div'>
              <Pagination
                count={totalPages}
                page={pages}
                variant="outlined"
                shape="rounded"
                onChange={handleChange}
              />
            </div>
          ) : (
            ''
          )}
          <FilterModal props={{ show: showModal, setShow: setShowModal, setFilters: setFilters, filters }} />
        </div>
      </div>
    );
};

export default Deposit;
