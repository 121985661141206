import axios from 'axios';
import { notRefresh } from './AuthService';
import { login } from './Issuer/registerService';
import { loginAdmin } from './Admin/registerService';

const baseUrl = process.env.REACT_APP_BASE_URL;
const axiosInstance = axios.create({});

axios.interceptors.request.use(
  (request) => {

    if (localStorage.getItem('token')) {
      request.headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    }

    request.headers['Origin'] = window.location.origin;
    request.headers['Content-Type'] = 'application/x-www-form-urlencoded';

    return request;
  },
  (error) => {
    return Promise.reject(error);
  },
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const body = {
          grant_type: 'refresh_token',
          client_id: process.env.REACT_APP_CLIENT_ID,
          client_secret: process.env.REACT_APP_CLIENT_SECRET,
          refresh_token: localStorage.getItem('refresh_token'),
        };
        if (!body.refresh_token && localStorage.getItem('token')) {
          notRefresh(401);
        }
        const { data } =
          process.env.REACT_APP_BUILD_TARGET === 'investor'
            ? await loginKeycloak(body)
            : process.env.REACT_APP_BUILD_TARGET === 'issuer'
              ? await login(body) : process.env.REACT_APP_BUILD_TARGET === 'admin' ? await loginAdmin(body)
                : await login(body);
        localStorage.setItem('token', data.access_token);

        localStorage.setItem('refresh_token', data.refresh_token);
        const newRequest = { ...originalRequest };
        newRequest.headers.Authorization = `Bearer ${data.access_token}`;
        return retryRequest(newRequest);
      } catch (error) {
        notRefresh(error.response.status);
      }
    }
    return Promise.reject(error);
  },
);

async function retryRequest(request) {
  try {
    const response = await axiosInstance(request);
    return response;
  } catch (error) {
    if (error.response && error.response.status !== 401) {
      throw error;
    }
    return retryRequest(request);
  }
}

export function get(path, params) {
  return axios.get(`${baseUrl}/${path}`, { params });
}

export function post(path, body) {
  return axios.post(`${baseUrl}/${path}`, body);
}

export function patch(path, body) {
  return axios.patch(`${baseUrl}/${path}`, body);
}

export function postWithConfig(path, body, config) {
  return axios.post(`${baseUrl}/${path}`, body, config);
}

export function put(path, body) {
  return axios.put(`${baseUrl}/${path}`, body);
}

export function responseIsSuccess(status) {
  return status >= 200 && status < 300;
}

export function loginKeycloak(body) {
  console.log()
  return axios.post(`${process.env.REACT_APP_KEYCLOAK_URL}/realms/criptech/protocol/openid-connect/token`, body, {
    headers: {
      'content-type': 'application/x-www-form-urlencoded'
    },
  });
}

export const defaults = {
  headers: {
    Authorization: '',
  },
};
