import { useEffect, useState } from 'react';

import { Loading, Table, Translator } from '@components';
import { usePurchase } from '@contexts-investor';
import { emptyStatement, noData } from '@assets';
import { Dropdown } from 'react-bootstrap';
import StatusCard from './StatusCard';
import { COLUMNS } from './TableColumns/index';
import FilterModal from './FilterModal';
import Pagination from '@mui/material/Pagination';

const Statement = () => {
  const { myPurchases, listPurchases, loadingPurchase, totalPurchases } = usePurchase();
  const [purchases, setPurchases] = useState();
  const [showModal, setShowModal] = useState(false);
  const [filters, setFilters] = useState();
  const [pages, setPages] = useState(0);
  const itemsPerPage = 12;
  const totalPages = Math.ceil(totalPurchases / itemsPerPage);

  const handleChange = (event, value) => {
    setPages(value)
  };

  useEffect(() => {
    const userId = localStorage.getItem('jwt-userId');
    listPurchases(pages, filters, userId);
  }, [filters, setFilters, pages, setPages]);

  useEffect(() => {
    const options = { minimumFractionDigits: 2 };
    if (myPurchases) {
      const formatedPurchases = Array.from(myPurchases).map((item) => {
        const action = (
          <Dropdown style={{ color: 'var(--orange)' }}>
            <Dropdown.Toggle as="div" className="i-false sidebar-select">
              <i className="material-icons statement-action cursor-pointer">more_horiz</i>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => {
                  window.open(`${process.env.REACT_APP_BLOCKCHAIN_URL}${item.transactionHash}`, '_blank');
                }}
              >
                <Translator path="statementPage.blockchain" />
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        );

        const total = (+item.boughtValue + item.boughtQuantity * +item.token.yieldValue).toFixed(2);
        return {
          ...item,
          boughtValue: 'R$ ' + new Intl.NumberFormat('pt-BR', options).format(parseFloat(item.boughtValue)),
          createdAt: new Date(item.createdAt).toLocaleDateString('pt-BR'),
          receive:
            item.status === 'INCAPTURE'
              ? 'R$ ' + new Intl.NumberFormat('pt-BR', options).format(parseFloat(total))
              : '-',
          received:
            item.status === 'CAPTATED' || item.status === 'LIQUIDATED'
              ? 'R$ ' + new Intl.NumberFormat('pt-BR', options).format(parseFloat(total))
              : '-',
          return:
            item.status === 'CANCELED'
              ? 'R$ ' + new Intl.NumberFormat('pt-BR', options).format(parseFloat(item.boughtValue))
              : '-',
          action:
            item.status === 'CAPTATED' || item.status === 'LIQUIDATED' || item.status === 'INCAPTURE'
              ? action
              : undefined,
          status: <StatusCard status={item.status} />,
        };
      });
      setPurchases(formatedPurchases);
    }
  }, [myPurchases]);

  if (loadingPurchase) {
    return <Loading />;
  }

  if (myPurchases && purchases) {
    return (
      <div className="page-wrapper">
        <div className="row justify-content-between">
          <h3 className="col-6 statement-header" style={{ whiteSpace: 'nowrap', marginBottom: '1.25rem' }}>
            <Translator path="statementPage.title" />
          </h3>
          <button
            className={`btn btn-orange col-2 ${purchases.length === 0 && !filters ? 'disabled' : ''}`}
            style={{ marginBottom: '1.25rem' }}
            onClick={() => setShowModal(true)}
          >
            <Translator path="statementPage.filter" />
          </button>
          <Table
            props={{ tableData: purchases, tableColumns: COLUMNS, emptyImage: filters ? noData : emptyStatement }}
          />
          {totalPages > 1 ? (
            <div className='pagination-div'>
              <Pagination
                count={totalPages}
                page={pages}
                variant="outlined"
                shape="rounded"
                onChange={handleChange}
              />
            </div>
          ) : (
            ''
          )}
        </div>
        <FilterModal props={{ show: showModal, setShow: setShowModal, setFilters: setFilters, filters }} />
      </div>
    );
  };
};

export default Statement;
