import { createContext, useCallback, useContext, useState } from 'react';
import swal from 'sweetalert';

import { useTranslation } from 'react-i18next';
import * as tokenService from '../../services/Investor/tokenService';

export const TokenContext = createContext({});

const TokenProvider = ({ children }) => {
  const { t } = useTranslation();
  const [myTokens, setMyTokens] = useState();
  const [loadingMyTokens, setLoadingMyTokens] = useState();

  const [token, setToken] = useState();
  const [loadingToken, setLoadingToken] = useState(false);

  const [tokens, setTokens] = useState();
  const [totalToken, setTotalToken] = useState();
  const [highlightToken, setHighlightToken] = useState();
  const [tokenDetail, setTokenDetail] = useState();
  const [loadingTokens, setLoadingTokens] = useState(false);

  const [highlightedTokens, setHighlightedTokens] = useState();

  const loadMyTokens = useCallback(async (params = { status: null, initialDate: null, finalDate: null }, userId) => {
    setLoadingMyTokens(true);
    try {
      const tokenparams = {
        ...params,
        userId: userId,
      };
      const data = await tokenService.getMyTokens(tokenparams);
      setMyTokens(data.data.data);
    } catch (error) {
      const errorTitle = t('apiErrors.error').toString();
      const errorMsg = t('apiErrors.tokenList').toString();
      swal(errorTitle, errorMsg, 'error');
    } finally {
      setLoadingMyTokens(false);
    }
  }, []);

  const listTokens = useCallback(async (page = 0, filters, perPage) => {
    setLoadingTokens(true);
    try {
      const params = {
        page: page ? page - 1 : 0,
        pageSize: perPage,
        ...(filters && { ...filters }),
        userId: localStorage.getItem('jwt-userId'),
      };
      const data = await tokenService.listTokens(params);
      setTotalToken(data.data.total);
      setTokens(data.data.data);
    } catch (error) {
      const errorTitle = t('apiErrors.error').toString();
      const errorMsg = t('apiErrors.tokenList').toString();
      swal(errorTitle, errorMsg, 'error');
    } finally {
      setLoadingTokens(false);
    }
  }, []);

  const listTokensLite = useCallback(async (page = 0, filters, perPage) => {
    setLoadingTokens(true);
    try {
      const params = {
        page: page ? page - 1 : 0,
        pageSize: 100,
        ...(filters && { ...filters }),
      };
      const data = await tokenService.listTokensLite(params);
      setTotalToken(data.data.total);
      setTokens(data.data.data);
    } catch (error) {
      const errorTitle = t('apiErrors.error').toString();
      const errorMsg = t('apiErrors.tokenList').toString();
      swal(errorTitle, errorMsg, 'error');
    } finally {
      setLoadingTokens(false);
    }
  }, []);


  const getToken = useCallback(async (tokenId) => {
    setLoadingToken(true);
    try {
      const token = await tokenService.getToken(tokenId);
      setToken(token.data);
    } catch (error) {
      const errorTitle = t('apiErrors.error').toString();
      const errorMsg = t('apiErrors.tokenDetails').toString();
      swal(errorTitle, errorMsg, 'error');
    } finally {
      setLoadingToken(false);
    }
  }, []);

  const getTokenDetails = useCallback(async (tokenId) => {
    setLoadingTokens(true);
    try {
      const { data } = await tokenService.tokenDetails(tokenId);
      setTokenDetail(data);
    } catch (error) {
      const errorTitle = t('apiErrors.error').toString();
      const errorMsg = t('apiErrors.tokenDetails').toString();
      swal(errorTitle, errorMsg, 'error');
    } finally {
      setLoadingTokens(false);
    }
  }, []);

  const getHighlightedTokens = useCallback(async (page = 0, filters) => {
    setLoadingTokens(true);

    try {
      const params = {
        page: page ? page - 1 : 0,
        pageSize: 12,
        filter: 'HIGHLIGHT',
        ...(filters && { ...filters }),
      };

      const data = await tokenService.listTokens(params);

      setHighlightedTokens(data.data.data);
    } catch (error) {
      const errorTitle = t('apiErrors.query').toString();
      const errorMsg = t('apiErrors.highlightedTokens').toString();
      swal(errorTitle, errorMsg, 'error');
    } finally {
      setLoadingTokens(false);
    }
  }, []);

  const getLatestHighlightToken = useCallback(async () => {
    setLoadingTokens(true);
    try {
      const userId = localStorage.getItem('jwt-userId');
      const params = {
        page: 0,
        pageSize: 1,
        filter: 'HIGHLIGHT',
        status: 'INCAPTURE',
        userId,
      };
      const data = await tokenService.listTokens(params);

      setHighlightToken(data.data.data);
    } catch (error) {
      const errorTitle = t('apiErrors.error').toString();
      const errorMsg = t('apiErrors.tokenList').toString();
      swal(errorTitle, errorMsg, 'error');
    } finally {
      setLoadingTokens(false);
    }
  }, []);

  return (
    <TokenContext.Provider
      value={{
        myTokens,
        loadingMyTokens,
        tokens,
        loadingTokens,
        token,
        loadingToken,
        loadMyTokens,
        listTokens,
        listTokensLite,
        highlightToken,
        getToken,
        getTokenDetails,
        tokenDetail,
        getHighlightedTokens,
        getLatestHighlightToken,
        highlightedTokens,
        totalToken,
      }}
    >
      {children}
    </TokenContext.Provider>
  );
};

const useToken = () => {
  return useContext(TokenContext);
};

export { TokenProvider, useToken };
