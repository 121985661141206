import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useProfile } from '@contexts-investor';
import { Buffer } from 'buffer';

import { Translator, Loading } from '@components';
import { noPreview } from '@assets';

import { cpfMask } from '@utils';
import { formatDate } from 'date-utils-2020';

const Profile = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [preview, setPreview] = useState();
  const [highlight, setHighlight] = useState('Dados');
  const [wallet, setWallet] = useState();
  const [userInfo, setUserInfo] = useState();
  const { profile, loadingProfile, loadProfile, downloadImageInvestor, imageLoading } = useProfile();

  useEffect(() => {
    const userId = localStorage.getItem('jwt-userId');
    loadProfile(userId);
  }, [localStorage.getItem('jwt-userId')]);

  useEffect(() => {
    if (profile) {
      const user = {
        ...profile.physicalUser,
        cpf: cpfMask(profile.physicalUser.cpf),
        monthlyIncome: new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(
          parseFloat(profile.physicalUser.monthlyIncome),
        ),
      };
      setUserInfo(user);
    }
  }, [profile]);

  useEffect(() => {
    const downloadData = async (imageURL) => {
      const base64Image = await downloadImageInvestor(imageURL.replaceAll('/', '%2F'));
      const downloadedImage = Buffer.from(base64Image.data, 'binary').toString('base64');
      setPreview(downloadedImage);
    };

    if (userInfo && userInfo.image) downloadData(userInfo.image);
  }, [userInfo && userInfo.image]);

  if (loadingProfile) return <Loading />;

  if (profile && userInfo)
    return (
      <div className="page-wrapper">
        <div className="row justify-content-between">
          <div style={{ maxWidth: '30rem' }}>
            <div
              className="row col-12 d-flex align-items-center justify-content-center profile-box"
              style={{ height: '21rem', marginBottom: '2.5rem' }}
            >
              <div className="col-xl-8 col-5 d-flex flex-column align-items-center">
                <div className="author-profile">
                  <div className="author-media">
                    {imageLoading ? (
                      <>
                        <div
                          className="loading-spinner"
                          style={{
                            width: '140px',
                            height: '140px',
                            position: 'absolute',
                          }}
                        />
                        <img
                          src={noPreview}
                          alt=""
                          style={{ width: '140px', height: '140px', margin: '0 !important' }}
                        />
                      </>
                    ) : (
                      <>
                        {preview ? (
                          <img
                            src={`data:image/jpeg;base64,${preview} `}
                            alt=""
                            style={{ width: '140px', height: '140px' }}
                          />
                        ) : (
                          <label
                            style={{
                              width: '140px',
                              height: '140px',
                              fontSize: '2.25rem',
                              color: 'white',
                              borderRadius: '5rem',
                              backgroundColor: '#FF9900'
                            }}
                            className="d-flex align-items-center justify-content-center"
                          >
                            {userInfo && userInfo.name[0].toUpperCase()}
                          </label>
                        )}
                      </>
                    )}
                  </div>
                  <div className="author-info">
                    <h6 className="title">{userInfo.name}</h6>
                  </div>
                </div>
              </div>
            </div>
            {profile?.status !== 'ACTIVE' ? (
              <div className="col-12 d-flex align-items-center border border-light rounded-1">
                <i className="material-icons d-flex align-items-center mx-2">info_outline</i>
                <p
                  style={{
                    margin: 'auto 0',
                    fontWeight: 400,
                    fontSize: '1rem',
                    lineHeight: '1.5rem',
                    color: 'var(--gray)',
                  }}
                >
                  <Translator path="profilePage.userNotAllowed" />
                </p>
              </div>
            ) : (
              ''
            )}
          </div>
          <div
            className={`row col-xxl-7 col-xl-6 col-md-12 col-sm-12 col-xs-12 col-12 ${highlight === 'Carteira' ? 'profile-box-small' : 'profile-box'
              }`}
          >
            <div
              className="row col-xl-12 col-lg-12 col-sm-12 col-xs-12 d-flex align-items-center justify-content-start cursor-pointer profile-header-border"
              style={{ height: '10vh' }}
            >
              {/* <div
                className={`col-xl-3 col-lg-3 col-sm-3 col-6 d-flex align-items-center justify-content-center  ${highlight === 'Carteira' ? 'profile-highlight' : ''
                  }`}
                style={{ height: '100%' }}
                onClick={() => {
                  setHighlight('Carteira');
                }}
              >
                <p className="profile-menu-headers">
                  <Translator path="profilePage.wallet" />
                </p>
              </div> */}
              <div
                className={`col-xl-3 col-lg-3 col-sm-3 d-flex col-6 align-items-center justify-content-center ${highlight === 'Dados' ? 'profile-highlight' : ''
                  }`}
                style={{ height: '100%' }}
                onClick={() => {
                  setHighlight('Dados');
                }}
              >
                <p className="profile-menu-headers">
                  <Translator path="profilePage.data" />
                </p>
              </div>
              {/* {highlight === 'Carteira' ? (
                <div className="row col-12 profile-row-modifier">
                  <label className="col-12 profile-data-labels">
                    <Translator path="profilePage.address" />
                  </label>
                  <div className="row col-12" style={{ padding: 0, margin: 0 }}>
                    <input
                      className="col-6 profile-data-input"
                      placeholder={t('profilePage.walletPlaceholder')}
                      value={userInfo.wallet ? userInfo.wallet : wallet}
                      readOnly={userInfo.wallet ? true : false}
                      onChange={(e) => setWallet(e.target.value)}
                    />
                  </div>
                  <button
                    className="col-2 btn btn-orange d-flex justify-content-center"
                    style={{ marginTop: '2.5rem' }}
                  >
                    <Translator path="profilePage.submit" />
                  </button>
                </div>
              ) : ( */}
              <div className="row col-12 profile-row-modifier">
                <div className="row col-sm-6" style={{ margin: 0, padding: '0 0.625rem 0 0' }}>
                  <label className="col-12 profile-data-labels">
                    <Translator path="profilePage.name" />
                  </label>
                  <div className="row col-12" style={{ padding: 0, margin: 0 }}>
                    <input className="col-12 profile-data-input-disabled" readOnly value={userInfo.name} />
                  </div>
                  <label className="col-12 profile-data-labels">
                    <Translator path="profilePage.occupation" />
                  </label>
                  <div className="row col-12" style={{ padding: 0, margin: 0 }}>
                    <input className="col-12 profile-data-input-disabled" readOnly value={userInfo.occupation} />
                  </div>
                  <label className="col-12 profile-data-labels">
                    <Translator path="profilePage.cpf" />
                  </label>
                  <div className="row col-12" style={{ padding: 0, margin: 0 }}>
                    <input className="col-12 profile-data-input-disabled" readOnly value={userInfo.cpf} />
                  </div>
                </div>
                <div className="row col-sm-6" style={{ margin: 0, padding: '0 0 0 0' }}>
                  <label className="col-12 profile-data-labels">
                    <Translator path="profilePage.email" />
                  </label>
                  <div className="row col-12" style={{ padding: 0, margin: 0 }}>
                    <input className="col-12 profile-data-input-disabled" readOnly value={userInfo.email} />
                  </div>
                  <label className="col-12 profile-data-labels">
                    <Translator path="profilePage.income" />
                  </label>
                  <div className="row col-12" style={{ padding: 0, margin: 0 }}>
                    <input
                      className="col-12 profile-data-input-disabled"
                      readOnly
                      value={`R$ ${userInfo.monthlyIncome}`}
                    />
                  </div>
                  <label className="col-12 profile-data-labels">
                    <Translator path="profilePage.birth" />
                  </label>
                  <div className="row col-12" style={{ padding: 0, margin: 0 }}>
                    <input
                      className="col-12 profile-data-input-disabled"
                      readOnly
                      value={formatDate(userInfo.birthDate, 'dd/MM/yyyy')}
                    />
                  </div>
                </div>
                <button
                  className="col-md-3 col-lg-3 col-sm-4 col-xxl-3 btn btn-orange d-flex justify-content-center"
                  style={{ marginTop: '2.5rem' }}
                  onClick={() => {
                    navigate('/profile/edit');
                  }}
                >
                  <Translator path="profilePage.editSubmit" />
                </button>
              </div>
              {/* )} */}
            </div>
          </div>
        </div>
      </div>
    );
};

export default Profile;
