import swal from 'sweetalert';

import { loginKeycloak } from '../../services/api';
import { saveTokenInLocalStorage, saveUserIdInLocalStorage, signUp } from '../../services/AuthService';

//import { useNavigate } from "react-router-dom";

export const SIGNUP_CONFIRMED_ACTION = '[signup action] confirmed signup';
export const SIGNUP_FAILED_ACTION = '[signup action] failed signup';
export const LOGIN_CONFIRMED_ACTION = '[login action] confirmed login';
export const LOGIN_FAILED_ACTION = '[login action] failed login';
export const LOADING_TOGGLE_ACTION = '[Loading action] toggle loading';
export const LOGOUT_ACTION = '[Logout action] logout action';
export function signupAction(body, navigate) {
  return (dispatch) => {
    signUp(body)
      .then((response) => {
        navigate('/dashboard');
        //history.push('/dashboard');
      })
      .catch((error) => {
        if (error.response) {
          const errorMsg = JSON.stringify(error.response.data.message)
            .replace('[', '')
            .replace(']', '')
            .replace(',', '\n');
          swal('Erro', errorMsg, 'error');
          dispatch(signupFailedAction(errorMsg));
        }
      });
  };
}

export function loginAction(body, navigate, setLoading, { tokenId }, errObj, connected) {
  console.log(body)
  return async (dispatch) => {
    await loginKeycloak(body)
      .then(async (response) => {
        setLoading(false);

        saveTokenInLocalStorage(response.data.access_token);
        saveUserIdInLocalStorage(response.data.access_token);
        dispatch(loginConfirmedAction(response.data));
        if (connected === true) {
          localStorage.setItem('refresh_token', response.data.refresh_token);
        }
        if (tokenId !== 'none') {
          navigate(`/investment/${tokenId}`);
        } else navigate('/login/account');
      })
      .catch((error) => {
        setLoading(false);
        swal(errObj.title, errObj.body, 'error');
        dispatch(loginFailedAction(error));
      });
  };
}

export function loginFailedAction(data) {
  return {
    type: LOGIN_FAILED_ACTION,
    payload: data,
  };
}

export function loginConfirmedAction(data) {
  return {
    type: LOGIN_CONFIRMED_ACTION,
    payload: data,
  };
}

export function confirmedSignupAction(payload) {
  return {
    type: SIGNUP_CONFIRMED_ACTION,
    payload: payload,
  };
}

export function signupFailedAction(message) {
  return {
    type: SIGNUP_FAILED_ACTION,
    payload: message,
  };
}

export function loadingToggleAction(status) {
  return {
    type: LOADING_TOGGLE_ACTION,
    payload: status,
  };
}

export function logoutAction(payload) {
  return {
    type: LOGOUT_ACTION,
    payload: payload,
  };
}
