import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useProfile } from '@contexts-investor';
import { Buffer } from 'buffer';
import { Translator, Loading } from '@components';
import { noPreview } from '@assets';
import { cpfMask, cnpjMask, phoneMask, socialContractMask } from '@utils';
import { Wizard } from 'react-use-wizard';
import MyDataEdit from './myDataEdit';
import MyCompanyEdit from './myCompanyEdit';
import swal from 'sweetalert';

const ProfileEditPJ = () => {
  const { t } = useTranslation();
  const [preview, setPreview] = useState();
  const [userInfo, setUserInfo] = useState();
  const {
    profile,
    loadingProfile,
    loadProfile,
    editProfile,
    loadingButton,
    uploadImage,
    downloadImageInvestor,
    imageLoading,
  } = useProfile();
  const [address, setAddress] = useState();
  const [phone, setPhone] = useState();
  const [addressCorp, setAddressCorp] = useState();
  const [phoneCorp, setPhoneCorp] = useState();
  const [corpName, setCorpName] = useState();
  const [image, setImage] = useState();
  const [imageURL, setImageURL] = useState();
  const [size, setSize] = useState();
  const setters = { setAddress, setPhone, setAddressCorp, setPhoneCorp, setCorpName, setSize };
  const getters = { address, phone, addressCorp, phoneCorp, corpName, loadingButton }

  useEffect(() => {
    const userId = localStorage.getItem('jwt-userId');
    loadProfile(userId);
  }, [localStorage.getItem('jwt-userId')]);

  useEffect(() => {
    if (profile) {
      const user = {
        ...profile.legalUser,
        cpf: cpfMask(profile.legalUser.loginLegalUser.cpf),
        phone: phoneMask(profile.legalUser.loginLegalUser.phone),
        phoneCompany: phoneMask(profile.legalUser.phone),
        cnpj: cnpjMask(profile.legalUser.cnpj),
        socialContractName: socialContractMask(profile.legalUser.socialContractUrl)
      };
      setUserInfo(user);
    }
  }, [profile]);

  useEffect(() => {
    const downloadData = async (imageURL) => {
      const base64Image = await downloadImageInvestor(imageURL.replaceAll('/', '%2F'));
      const downloadedImage = Buffer.from(base64Image.data, 'binary').toString('base64');
      setPreview(downloadedImage);
    };

    const imageUploadHandler = async () => {
      const imageURL = await uploadImage(image[0]);
      setImageURL(imageURL);
      downloadData(imageURL);
    };

    if (image) imageUploadHandler();
    else if (userInfo && userInfo.image) downloadData(userInfo.image);
  }, [userInfo && userInfo.image, image]);

  useEffect(() => {
    const downloadData = async (imageURL) => {
      const base64Image = await downloadImageInvestor(imageURL.replaceAll('/', '%2F'));
      const downloadedImage = Buffer.from(base64Image.data, 'binary').toString('base64');
      setPreview(downloadedImage);
    };

    if (userInfo && userInfo.image) downloadData(userInfo.image);
  }, [userInfo && userInfo.image]);

  const isPhone = (phone) =>
    /^\(?(\d{2})\)?[- ]?(\d{5})[- ]?(\d{4})$/.test(phone) ||
    /^\(?(\d{2})\)?[- ]?(\d{4})[- ]?(\d{4})$/.test(phone) ||
    /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(phone);

  const handleEdit = () => {

    let error;

    if ((phone !== undefined) && (!isPhone(phone))) {
      error = 1;
    }
    if ((phoneCorp !== undefined) && (!isPhone(phoneCorp))) {
      error = 1;
    }

    if (error === 1) {
      swal(t('profilePage.phone').toString(), t('withdrawPage.secondStep.phoneError').toString(), 'error');
      return;
    }

    const body = {
      loginLegalUser: {
        address: address ? address : userInfo.loginLegalUser.address,
        phone: phone ? phone : userInfo.loginLegalUser.phone,
      },
      legalUser: {
        image: imageURL ? imageURL : userInfo.image,
        address: addressCorp ? addressCorp : userInfo.address,
        corporateName: corpName ? corpName : userInfo.corporateName,
        phone: phoneCorp ? phoneCorp : userInfo.phone,
      },

    };
    if (profile) editProfile(profile.userId, body, 'pj');

  };

  if (loadingProfile) return <Loading />;

  if (profile && userInfo)
    return (
      <div className="page-wrapper">
        <div className="row justify-content-between">
          <div style={{ maxWidth: '30rem' }}>
            <div
              className="row col-12 d-flex align-items-center justify-content-center profile-box"
              style={{ height: '21rem', marginBottom: '2.5rem' }}
            >
              <div className="col-xl-8 col-5 d-flex flex-column align-items-center">
                <div className="author-profile">
                  <div className="author-media">
                    {imageLoading ? (
                      <>
                        <div
                          className="loading-spinner"
                          style={{
                            width: '140px',
                            height: '140px',
                            position: 'absolute',
                          }}
                        />
                        <img
                          src={noPreview}
                          alt=""
                          style={{ width: '140px', height: '140px', margin: '0 !important' }}
                        />

                      </>
                    ) : (
                      <>
                        {preview ? (
                          <img
                            src={`data:image/jpeg;base64,${preview} `}
                            alt=""
                            style={{ width: '140px', height: '140px' }}
                          />
                        ) : (
                          <label
                            style={{
                              width: '140px',
                              height: '140px',
                              fontSize: '2.25rem',
                              color: 'white',
                              borderRadius: '5rem',
                              backgroundColor: '#FF9900'
                            }}
                            className="d-flex align-items-center justify-content-center"
                          >
                            {userInfo && userInfo.corporateName[0].toUpperCase()}
                          </label>
                        )}
                        <div
                          className="upload-link"
                          title=""
                          data-toggle="tooltip"
                          data-placement="right"
                          data-original-title="update"
                          style={{ backgroundColor: '#FF9900' }}
                        >
                          <input
                            type="file"
                            className="update-file"
                            onChange={(e) => {
                              setImage(e.target.files);
                            }}
                          />
                          <i className="fa fa-camera" style={{ backgroundColor: '#FF9900' }}></i>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="author-info">
                    <h6 className="title">{userInfo.corporateName}</h6>
                  </div>
                </div>
              </div>
            </div>
            {profile?.status !== 'ACTIVE' ? (
              <div className="col-12 d-flex align-items-center border border-light rounded-1">
                <i className="material-icons d-flex align-items-center mx-2">info_outline</i>
                <p
                  style={{
                    margin: 'auto 0',
                    fontWeight: 400,
                    fontSize: '1rem',
                    lineHeight: '1.5rem',
                    color: 'var(--gray)',
                  }}
                >
                  <Translator path="profilePage.userNotAllowed" />
                </p>
              </div>
            ) : (
              ''
            )}
          </div>

          <Wizard>
            <MyDataEdit userInfo={userInfo} set={setters} get={getters} edit={handleEdit} />
            <MyCompanyEdit userInfo={userInfo} set={setters} get={getters} edit={handleEdit} />
          </Wizard>

        </div>
      </div>
    );
};

export default ProfileEditPJ;
