import { createContext, useCallback, useContext, useState } from 'react';
import swal from 'sweetalert';

import * as depositService from '../../services/Investor/depositService';
import { useTranslation } from 'react-i18next';

export const DepositContext = createContext({});

const DepositProvider = ({ children }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [myDeposits, setMyDeposits] = useState();
  const [currentDeposit, setCurrentDeposit] = useState();
  const [totalDeposits, setTotalDeposits] = useState();
  const [success, setSuccess] = useState(null);

  const getDeposits = useCallback(
    async (page, filters, userId) => {
      setLoading(true);
      try {
        const params = {
          page: page ? page - 1 : 0,
          pageSize: 12,
          ...(filters && { ...filters }),
          userId: userId,
        };

        const { data } = await depositService.getDeposits(params);
        setTotalDeposits(data.total);
        setMyDeposits(data.data);
      } catch (error) {
        console.error(error);
        const errorTitle = t('apiErrors.error').toString();
        const errorMsg = t('apiErrors.deposit').toString();
        swal(errorTitle, errorMsg, 'error');
      } finally {
        setLoading(false);
      }
    },
    [setLoading, setMyDeposits, myDeposits],
  );

  const deposit = useCallback(async (body) => {
    setLoading(true);
    try {
      const userId = localStorage.getItem('jwt-userId');
      body = { ...body, userId: userId };
      const { data } = await depositService.makeDeposit(body);
      setCurrentDeposit(data);
      setSuccess(true);
    } catch (error) {
      setSuccess(false);
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const closeModal = useCallback(async () => {
    setSuccess(null);
  }, []);

  return (
    <DepositContext.Provider
      value={{
        loading,
        getDeposits,
        myDeposits,
        currentDeposit,
        deposit,
        closeModal,
        success,
        totalDeposits,
      }}
    >
      {children}
    </DepositContext.Provider>
  );
};

const useDeposit = () => {
  return useContext(DepositContext);
};

export { DepositProvider, useDeposit };
