import { createContext, useCallback, useContext, useState } from 'react';
import swal from 'sweetalert';

import * as withdrawService from '../../services/Investor/withdrawService';
import { useTranslation } from 'react-i18next';

export const WithdrawContext = createContext({});

const WithdrawProvider = ({ children }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [myWithdraw, setMyWithdraw] = useState();
  const [totalWithdraw, setTotalWithdraw] = useState();

  const sendEmailToken = useCallback(async () => {
    try {
      const uid = localStorage.getItem('jwt-userId');
      await withdrawService.sentEmail(uid);
    } catch (error) {
      console.error(error);
    } finally {
    }
  }, []);

  const validateWithdraw = useCallback(
    async (code, userId) => {
      setLoading(true);
      try {
        const params = {
          token: code,
          userId: userId,
        };
        const data = await withdrawService.validadeWithdrawToken(params);
        setSuccess(true);
      } catch (error) {
        console.error(error);
        setSuccess(false);
      } finally {
        setLoading(false);
      }
    },
    [setLoading, loading],
  );

  const withdrawValue = useCallback(async (body) => {
    setLoading(true);
    try {
      const { data } = await withdrawService.newWithdraw(body);
      setSuccess(true);
    } catch (error) {
      setSuccess(false);
    } finally {
      setLoading(false);
    }
  }, []);

  const closeModal = useCallback(async () => {
    setSuccess(null);
  }, []);

  const listWithdraw = useCallback(
    async (page, filters, userId) => {
      setLoading(true);
      try {
        const params = {
          page: page ? page - 1 : 0,
          pageSize: 12,
          ...(filters && { ...filters }),
          userId: userId,
        };
        const { data } = await withdrawService.getWithdraw(params);
        setTotalWithdraw(data.total);
        setMyWithdraw(data.data);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    },
    [loading, myWithdraw, setMyWithdraw],
  );

  return (
    <WithdrawContext.Provider
      value={{
        validateWithdraw,
        loading,
        success,
        withdrawValue,
        sendEmailToken,
        closeModal,
        listWithdraw,
        myWithdraw,
        totalWithdraw,
      }}
    >
      {children}
    </WithdrawContext.Provider>
  );
};

const useWithdraw = () => {
  return useContext(WithdrawContext);
};

export { WithdrawProvider, useWithdraw };
