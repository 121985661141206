import jwtDecode from 'jwt-decode';

import { loginConfirmedAction } from '../store/actions/AuthActions';
import * as api from './api';
import axios from 'axios';


export function signUp(body) {
  return api.post('register/physicalUser', body);
}

export function signUpPJ(body) {
  return api.post('register/legalUser', body);
}

export function saveTokenInLocalStorage(tokenDetails) {
  localStorage.setItem('token', tokenDetails);
}

export async function validateRecaptchaToken(token) {
  try {
    const body = {
      response: token,
    };
    return await axios.post(process.env.REACT_APP_BASE_URL + '/recaptcha', body, {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    });
  } catch (error) {
    console.error('Erro na validação do token do reCAPTCHA:', error);
    return false;
  }
}

export function saveUserIdInLocalStorage(token) {
  const decoded = jwtDecode(token);
  console.log(decoded)
  if (process.env.REACT_APP_BUILD_TARGET === 'investor') {
    if (decoded.userId !== undefined) {
      localStorage.setItem('jwt-userId', decoded.userId);
    }
  } else if (process.env.REACT_APP_BUILD_TARGET === 'issuer') {
    localStorage.setItem('issuerLoginId', decoded.issuerLoginId);
    localStorage.setItem('issuerId', decoded.issuerId);
  }
}

export function checkAutoLogin(dispatch, navigate) {
  const tokenDetailsString = localStorage.getItem('token');

  if (!tokenDetailsString) {
    const path = window.location.pathname;
    const noAuthPaths = ['/publictokens', '/publictokenslite', '/register', '/registertype', '/registerpj', '/register/concluded', '/register/email', '/register/password', '/login', '/reset'];

    if (!noAuthPaths.includes(path)) {
      logout(navigate);
      return;
    }
  }
  dispatch(loginConfirmedAction(tokenDetailsString));
}

function removeStorage() {
  localStorage.removeItem('token');
  localStorage.removeItem('refresh_token');
  if (process.env.REACT_APP_BUILD_TARGET === 'investor') {
    localStorage.removeItem('jwt-userId');
    localStorage.removeItem('jwt-userId');
  } else if (process.env.REACT_APP_BUILD_TARGET === 'issuer') {
    localStorage.removeItem('issuerLoginId');
    localStorage.removeItem('issuerId');
  }
}

export function logout(navigate) {
  removeStorage();
  navigate('/login');
}

export function notRefresh(errorCode) {
  removeStorage();
  if (errorCode === 401) {
    window.location = '/'
  }
}

