import { noPreview } from '@assets';
import avatar from '@assets/images/avatar/1.jpg';
import { I18n, Translator } from '@components';
import { useProfile } from '@contexts-investor';
import { useIssuer } from '@contexts-issuer';
import { Buffer } from 'buffer';
import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';

import LogoutPage from './Logout';

const Header = ({ onNote, publicRoute }) => {
  const [rightSelect, setRightSelect] = useState('Eng');
  const [userInfo, setUserInfo] = useState();
  const [userType, setUserType] = useState('');
  const [preview, setPreview] = useState();
  const { loadProfile, profile, downloadImageInvestor } = useProfile();
  const { getIssuerProfile, getIssuerLoginId, issuerInfo, downloadUserImage, userImageBuffer, issuerProfile } =
    useIssuer();
  const userId = localStorage.getItem('jwt-userId');
  const issuerId = localStorage.getItem('issuerId');
  const navigate = useNavigate();

  //For fix header
  const [headerFix, setheaderFix] = useState(false);
  useEffect(() => {
    window.addEventListener('scroll', () => {
      setheaderFix(window.scrollY > 50);
    });
  }, []);

  useEffect(() => {
    if (issuerInfo && issuerInfo.image) downloadUserImage(issuerInfo.image.replaceAll('/', '%2F'));
  }, [issuerInfo]);

  useEffect(() => {
    if (userImageBuffer) {
      const image = Buffer.from(userImageBuffer, 'binary').toString('base64');
      setPreview(image);
    }
  }, [issuerInfo, userImageBuffer]);

  useEffect(() => {
    const downloadData = async (imageURL) => {
      const base64Image = await downloadImageInvestor(imageURL.replaceAll('/', '%2F'));
      const downloadedImage = Buffer.from(base64Image.data, 'binary').toString('base64');
      setPreview(downloadedImage);
    };

    if (userInfo && userInfo?.physicalUser?.image) {
      downloadData(userInfo?.physicalUser?.image);
    }
    if (userInfo && userInfo?.legalUser?.image) {
      downloadData(userInfo?.legalUser?.image);
    }
  }, [userInfo]);

  useEffect(() => {
    if (process.env.REACT_APP_BUILD_TARGET === 'investor') {
      const userId = localStorage.getItem('jwt-userId');
      if (!publicRoute && userId !== (null && undefined)) {
        loadProfile(userId);
      }
    } else if (process.env.REACT_APP_BUILD_TARGET === 'issuer') {
      if (publicRoute === false && issuerId) {
        const issuerLoginId = localStorage.getItem('issuerLoginId');
        const issuerId = localStorage.getItem('issuerId');
        getIssuerLoginId(issuerLoginId);
        getIssuerProfile(issuerId);
      }
    }
  }, []);

  useEffect(() => {
    if (profile && !publicRoute && process.env.REACT_APP_BUILD_TARGET === 'investor') {
      const user = {
        ...profile,
        url: null,
      };
      setUserInfo(user);
      setUserType(user.type);
    }
  }, [profile]);

  var path = window.location.pathname.split('/');
  var name = path[1];
  if (JSON.stringify(name).length > 30) name = 'investment';

  if (publicRoute) {
    return (
      <div className={`header ${headerFix ? 'is-fixed' : ''}`}>
        <div className="header-content">
          <nav className="navbar navbar-expand">
            <div className="collapse navbar-collapse justify-content-between">
              <div className="header-left">
                <div className="dashboard_bar" style={{ textTransform: 'capitalize' }}>
                  <Translator path={`titles.${name}`} />
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>
    );
  }
  return (
    <div className={`header ${headerFix ? 'is-fixed' : ''}`}>
      <div className="header-content">
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse justify-content-between">
            <div className="header-left">
              <div className="dashboard_bar" style={{ textTransform: 'capitalize' }}>
                <Translator path={`titles.${name}`} />
              </div>
            </div>
            <div className="navbar-nav header-right">
              <div className="dz-side-menu">
                <div className="search-coundry">
                  <I18n />
                </div>
                <ul>
                  <Dropdown as="li" className="nav-item dropdown header-profile">
                    <Dropdown.Toggle variant="" as="a" className="nav-link i-false c-pointer">
                      {preview ? (
                        <div className="position-relative">
                          <img
                            src={preview ? `data:image/jpeg;base64,${preview}` : noPreview}
                            alt=""
                            style={{ width: '3.125rem', height: '3.125rem' }}
                          />
                          {!(
                            userInfo?.status === 'ACTIVE' ||
                            (issuerProfile && issuerProfile.status === 'APPROVED')
                          ) ? (
                            <span
                              className="position-absolute top-0 start-100 translate-middle rounded-circle d-flex justify-content-center align-items-center"
                              style={{
                                backgroundColor: '#E61D2B',
                                fontSize: '0.75rem',
                                color: 'white',
                                height: '1.5rem',
                                width: '1.5rem',
                                textAlign: 'center',
                              }}
                            >
                              <span>
                                <strong>!</strong>
                              </span>
                            </span>
                          ) : (
                            ''
                          )}
                        </div>
                      ) : (
                        <div
                          className="row align-items-center justify-content-center "
                          style={{ gap: '4%', padding: '0rem 0.75rem' }}
                        >
                          <div
                            className=" col-6 myHeaderLetter position-relative"
                            style={{ width: '3.125rem', height: '3.125rem', borderRadius: '2.5rem' }}
                          >
                            {profile && (userInfo?.physicalUser?.name || userInfo?.legalUser?.corporateName) ? (
                              <>
                                <p
                                  style={{ margin: 0, color: 'white' }}
                                  className="d-flex align-items-center justify-content-center"
                                >
                                  {userInfo &&
                                    (userInfo?.physicalUser?.name[0].toUpperCase() ||
                                      userInfo?.legalUser?.corporateName[0].toUpperCase())}
                                </p>
                                {!(
                                  profile?.status === 'ACTIVE' ||
                                  (issuerProfile && issuerProfile.status === 'APPROVED')
                                ) ? (
                                  <span
                                    className="position-absolute top-0 start-100 translate-middle rounded-circle d-flex justify-content-center align-items-center"
                                    style={{
                                      backgroundColor: '#E61D2B',
                                      fontSize: '0.75rem',
                                      color: 'white',
                                      height: '1.5rem',
                                      width: '1.5rem',
                                      textAlign: 'center',
                                    }}
                                  >
                                    <span>
                                      <strong>!</strong>
                                    </span>
                                  </span>
                                ) : (
                                  ''
                                )}
                              </>
                            ) : issuerInfo && issuerInfo.name ? (
                              <>
                                <p
                                  style={{ margin: 0, color: 'white' }}
                                  className="d-flex align-items-center justify-content-center"
                                >
                                  {issuerInfo && issuerInfo.name[0].toUpperCase()}
                                </p>
                              </>
                            ) : (
                              <>
                                <p
                                  style={{ margin: 0, color: 'white' }}
                                  className="d-flex align-items-center justify-content-center"
                                >
                                  <i className="material-icons">person</i>
                                </p>
                                {profile?.status !== 'ACTIVE' && userInfo?.name ? (
                                  <span
                                    className="position-absolute start-100 translate-middle rounded-circle d-flex justify-content-center align-items-center"
                                    style={{
                                      backgroundColor: '#E61D2B',
                                      fontSize: '0.75rem',
                                      color: 'white',
                                      height: '1.5rem',
                                      width: '1.5rem',
                                      textAlign: 'center',
                                    }}
                                  >
                                    <span>
                                      <strong>!</strong>
                                    </span>
                                  </span>
                                ) : (
                                  ''
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      )}
                    </Dropdown.Toggle>
                    <Dropdown.Menu align="right" className="dropdown-menu dropdown-menu-end">
                      <Link
                        to={userType === 'legal person' ? '/profilepj' : 'profile'}
                        className="dropdown-item ai-icon position-relative"
                      >
                        <svg
                          id="icon-user1"
                          xmlns="http://www.w3.org/2000/svg"
                          className="text-primary me-1"
                          width={18}
                          height={18}
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                          <circle cx={12} cy={7} r={4} />
                        </svg>
                        <span className="ms-2">
                          <Translator path="titles.profile" />
                        </span>
                        {!(profile?.status === 'ACTIVE' || (issuerProfile && issuerProfile.status === 'APPROVED')) ? (
                          <span
                            className="position-absolute translate-middle p-2 rounded-circle d-flex justify-content-center align-items-center"
                            style={{
                              backgroundColor: '#E61D2B',
                              fontSize: '0.75rem',
                              color: 'white',
                              height: '1.5rem',
                              width: '1.5rem',
                              textAlign: 'center',
                              top: '50%',
                              left: '75%',
                            }}
                          >
                            <span>
                              <strong>!</strong>
                            </span>
                          </span>
                        ) : (
                          ''
                        )}
                      </Link>
                      {process.env.REACT_APP_BUILD_TARGET === 'investor' ? (
                        <Link to="/login/account" className="dropdown-item ai-icon position-relative">
                          <span style={{ display: 'flex', alignItems: 'center', gap: '0.4rem' }}>
                            <i className="material-icons" style={{ color: 'orange' }}>
                              swap_horiz
                            </i>
                            <Translator path={'menuLabel.account'} />
                          </span>
                        </Link>
                      ) : (
                        ''
                      )}
                      <LogoutPage />
                    </Dropdown.Menu>
                  </Dropdown>
                </ul>
                {process.env.REACT_APP_BUILD_TARGET === 'investor' ? (
                  <Button
                    className={`col-5 action btn btn-orange btn-header`}
                    onClick={() => navigate('/wallet')}
                    style={{ padding: '0', height: '3.5rem', width: '8rem' }}
                  >
                    {process.env.REACT_APP_BUILD_TARGET === 'investor' ? (
                      <Translator path={'dashboard.stepsCard.depositBtn'} />
                    ) : (
                      <Translator path={'pages.wallet.depositBtn'} />
                    )}
                  </Button>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;
