import { Translator } from '@components';
import { Button } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import InvestmentModal from '../InvestmentModal';
import { Buffer } from 'buffer';

import { arrow, noPreview } from '@assets';
import { usePurchase, useProfile } from '@contexts-investor';
import { useNavigate } from 'react-router-dom';

const ConfirmInvestment = ({ flip, tokenId, quantity, token, totalValue }) => {
  const { buyToken, loadingPurchase, closeModal } = usePurchase();
  const { profile, downloadImageInvestor } = useProfile();
  const [showModal, setShowModal] = useState(false);
  const [userImage, setUserImage] = useState();
  const navigate = useNavigate();

  const handleInvestment = () => {

    const userId = localStorage.getItem('jwt-userId');

    const body = {
      tokenId,
      quantity,
      userId,
      isLegalUser: profile.physicalUser ? false : true
    }
    buyToken(body);
  };

  const onHideModal = () => {
    closeModal(navigate, tokenId);
  };

  useEffect(() => {
    const getImage = async () => {
      let base64Image;
      if (profile?.physicalUser) {
        base64Image = await downloadImageInvestor(profile.physicalUser?.image?.replaceAll('/', '%2F'));
      } else if (profile?.legalUser) {
        base64Image = await downloadImageInvestor(profile.legalUser?.image?.replaceAll('/', '%2F'));
      }
      if (base64Image !== undefined) {
        const downloadedImage = Buffer.from(base64Image.data, 'binary').toString('base64');
        setUserImage(downloadedImage);
      }
    };
    if (profile) getImage();
  }, [profile]);

  if (loadingPurchase) {
    return (
      <div
        className="col-lg-12 d-flex flex-column invest-label"
        style={{ padding: '0 2rem 0 2rem', maxHeight: '552px' }}
      >
        <div className="d-flex align-items-center justify-content-center vh-100">
          <div className="spinner-border text-warning" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="col-lg-12 d-flex flex-column invest-label" style={{ padding: '0 2rem 0 2rem', maxHeight: '552px' }}>
      <p
        className="mb-0 info-buy-title col-lg-6"
        style={{ whiteSpace: 'nowrap', padding: 0, marginTop: '2.5rem', lineHeight: '3rem' }}
      >
        <Translator path="investment.buyTokenPage.investTitle" />
      </p>
      <p className="buy-confirm mb-0 col-lg-6 fs-5 lh-base" style={{ whiteSpace: 'nowrap', marginTop: '1.25rem' }}>
        <Translator path="investment.confirmPurchase.confirmation" />
      </p>
      <div className="buy-schema">
        <span className="d-flex flex-column lh-base">
          <img className="token-image" src={token.image} />
          {token.name}
        </span>
        <img src={arrow} />
        <span className="d-flex flex-column lh-base">
          {userImage ? (
            <img className="token-image" src={`data:image/jpeg;base64,${userImage}`} />
          ) : (
            <i className="material-icons">account_circle</i>
          )}
          <Translator path="investment.confirmPurchase.account" />
        </span>
      </div>
      <div className="actions d-flex flex-column justify-content-center ">
        <Button variant="primary" size="lg" className="text-center" id="btn" onClick={handleInvestment}>
          <Translator path={'investment.confirmPurchase.confirm'} />
        </Button>
        <Button variant="outline-primary" size="lg" id="btn-outlined" onClick={(e) => flip(e)}>
          <Translator path={'investment.confirmPurchase.back'} />
        </Button>
      </div>
      <InvestmentModal show={showModal} token={token} quantity={quantity} totalValue={totalValue} hide={onHideModal} />
    </div>
  );
};

export default ConfirmInvestment;
